export enum SiteAssistantState {
  ACTIVE = 'active',
  NOTIFY = 'notify',
}

export enum SiteAssistantPagePath {
  EXCHANGE = '^/$',
  LOGIN = '^/login$',
  REGISTRATION = '^/registration$',
  RECOVERY_INIT = '^/recovery/init$',
  EXCHANGE_PAY = '^/crypto/pay/.*$', // Using .* to match any characters after /pay/
  BONUSES = '^/bonus$',
  RESERVES = '^/reserve$',
  PARTNERS_INFO = '^/partner/info$',
  PROFILE_PARTNERS = '^/profile/partners$',
  FAQ = '^/faq$',
  DASHBOARD = '^/profile/dashboard$',
  PERSONAL_INFO = '^/profile/info$',
  EXCHANGE_BIDS = '^/profile/bids$',
  CRYPTO_CHECK = '^/profile/crypto-check$',
  PROFILE_BONUSES = '^/profile/bonus$',
  PROFILE_SETTINGS = '^/profile/settings$',
  API = '^/profile/api$',
}
