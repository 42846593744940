import { ENV } from 'env/env.config';

const widgetScript = (key: string) => `
 (function(d, t) {
        var BASE_URL = "https://chat.facebok.bz";
        var g = d.createElement(t), s = d.getElementsByTagName(t)[0];
        g.src = BASE_URL + "/packs/js/sdk.js";
        g.defer = true;
        g.async = true;
        s.parentNode.insertBefore(g, s);
        g.onload = function() {
          window.chatwootSDK.run({
            websiteToken: '${key}',
            baseUrl: BASE_URL
          })
        }
      })(document, "script");
`;

export const chatwootWidget = {
  key: ENV.CHATWOOT_KEY,
  add: function () {
    if (this.key == null) return;
    const script = document.createElement('script');
    script.id = `script_${this.key}`;
    script.async = true;
    script.innerHTML = widgetScript(this.key);
    document.head.appendChild(script);
  },
  remove: function () {
    const elements = [document.getElementById(`script_${this.key}`)];

    elements.forEach((element) => {
      if (element != null) {
        element.remove();
      }
    });
  },
};
