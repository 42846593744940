import { CurrentUser } from 'api/user';

export type Subscriber = (isAuthenticated: boolean) => void;

export class AuthService {
  public isAuthenticated: boolean;
  private subscribers: Subscriber[];
  public userInfo?: CurrentUser;

  constructor(isAuthenticated: boolean, userInfo?: CurrentUser) {
    this.isAuthenticated = isAuthenticated;
    this.userInfo = userInfo;
    this.subscribers = [];
  }

  private notify = () => {
    this.subscribers.forEach((subscriber) => subscriber(this.isAuthenticated));
  };

  login = () => {
    this.setIsAuthenticated(true);
  };

  logout = () => {
    this.setUserInfo(undefined);
    this.setIsAuthenticated(false);
  };

  setIsAuthenticated = (isAuthenticated: boolean) => {
    this.isAuthenticated = isAuthenticated;
    this.notify();
  };

  setUserInfo = (userInfo?: CurrentUser) => {
    this.userInfo = userInfo;
  };

  subscribe = (subscriber: Subscriber) => {
    this.subscribers.push(subscriber);
    return () => {
      const subscriberIndex = this.subscribers.findIndex(
        (c) => c === subscriber
      );
      if (subscriberIndex === -1) return;
      this.subscribers.splice(subscriberIndex, 1);
    };
  };
}
