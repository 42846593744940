import * as React from 'react';
import { SVGProps } from 'react';

const SvgApiIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M3.333 10c0 2.106.976 3.983 2.5 5.205v2.013A8.33 8.33 0 0 1 1.666 10h1.667ZM10 1.667c4.32 0 7.874 3.289 8.291 7.5h-1.676A6.668 6.668 0 0 0 4.487 6.25h2.18v1.667h-5v-5h1.666V5A8.317 8.317 0 0 1 10 1.667Z"
          fill="#fff"
        />
        <g clipPath="url(#b)">
          <path
            d="M9.26 9.71a4.856 4.856 0 0 1 1.721-.996 1.942 1.942 0 0 0 3.038 0c.636.21 1.222.548 1.721.995a1.94 1.94 0 0 0 .715 2.314c.241.164.516.272.805.316a4.881 4.881 0 0 1 0 1.988 1.943 1.943 0 0 0-1.52 2.63c-.499.447-1.085.785-1.721.995a1.94 1.94 0 0 0-2.362-.538c-.262.126-.494.31-.676.538a4.855 4.855 0 0 1-1.721-.995 1.941 1.941 0 0 0-1.52-2.63 4.882 4.882 0 0 1 0-1.988 1.941 1.941 0 0 0 1.647-1.775 1.943 1.943 0 0 0-.127-.855Zm3.97 4.886a1.46 1.46 0 0 0-.349-2.678 1.459 1.459 0 1 0 .348 2.678Z"
            fill="#fff"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
        <clipPath id="b">
          <path
            fill="#fff"
            transform="translate(6.667 7.5)"
            d="M0 0h11.667v11.667H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgApiIcon;
