import {
  hide,
  show,
  sidebarRightClose,
  sidebarRightShow,
} from 'components/globalStyles/animation';

import styled from 'styled-components/macro';

export const Content = styled.div`
  z-index: 17;
  position: fixed;
  min-width: 280px;
  width: 280px;
  top: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colors.background.foreground};
  padding: 76px 0 48px;
  border-radius: 0;
  border: none;
  gap: 12px;
  animation: ${sidebarRightClose} ease-in-out 0.2s forwards;
  opacity: 0;
  pointer-events: none;
  &.active {
    animation: ${sidebarRightShow} ease-in-out 0.2s forwards;
    opacity: 1;
    pointer-events: auto;
  }
`;

export const Background = styled.div`
  z-index: 16;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(27, 28, 33, 0.8);
  animation: ${hide} ease-in-out 0.2s forwards;
  pointer-events: none;
  &.active {
    animation: ${show} ease-in-out 0.2s forwards;
    opacity: 1;
    pointer-events: auto;
  }
`;
