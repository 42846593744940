import { useState } from 'react';

const useAnimationTransition = (animationTimeout: number) => {
  const [animationTransitionState, setAnimationTransitionState] =
    useState<boolean>(false);

  const initAnimationTransition = () => {
    setAnimationTransitionState(true);

    const transitionTimeout = setTimeout(() => {
      setAnimationTransitionState(false);
    }, animationTimeout);

    return () => {
      clearTimeout(transitionTimeout);
    };
  };

  return { animationTransitionState, initAnimationTransition };
};

export default useAnimationTransition;
