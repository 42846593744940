import * as React from 'react';
import { SVGProps } from 'react';

const SvgGaIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.63 10.755h-1.49L7.965 7.119a1.222 1.222 0 0 1 .473-1.702c.203-.113.424-.167.644-.167a1.3 1.3 0 0 1 1.118.624l2.175 3.635-.745 1.246Z"
        fill="#FCBC05"
      />
      <path
        d="M16.312 18.583c-.616.344-1.406.14-1.763-.456l-2.174-3.636-2.175 3.636c-.356.595-1.146.8-1.763.456a1.238 1.238 0 0 1-.645-1.08c0-.211.056-.425.173-.622l2.175-3.635h4.47l2.175 3.635a1.223 1.223 0 0 1-.473 1.702Z"
        fill="#E94335"
      />
      <path
        d="M20.25 12c0 .688-.578 1.246-1.29 1.246h-4.35L13.865 12l-.745-1.246-.745-1.245 2.175-3.636c.356-.596 1.146-.8 1.763-.456.617.344.829 1.106.472 1.701l-2.175 3.637h4.35c.712 0 1.29.557 1.29 1.245Z"
        fill="#1973E8"
      />
      <path
        d="M11.63 10.755 10.885 12l-.745 1.246H5.79c-.712 0-1.29-.558-1.29-1.246 0-.344.144-.655.378-.88.234-.226.556-.365.912-.365h5.84Z"
        fill="#34A853"
      />
      <path
        d="M14.61 13.246h-4.47L10.885 12l.745-1.245.745-1.246.745 1.246.745 1.245.745 1.246Z"
        fill="#174FA7"
      />
    </svg>
  );
};
export default SvgGaIcon;
