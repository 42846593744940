import React, { FC } from 'react';

import classNames from 'classnames';

import { useTheme } from 'hooks/theme';

import ScButton from './styled';
import { ButtonProps, ButtonSize, ButtonType } from './types';

export const Button: FC<ButtonProps> = ({
  children,
  loading = false,
  btnType = ButtonType.PRIMARY,
  btnSize = ButtonSize.MEDIUM,
  ...props
}) => {
  const { images } = useTheme();

  return (
    <ScButton
      {...props}
      className={classNames(`${props.className} ${btnSize} ${btnType}`, {
        loading: loading,
      })}
    >
      {loading ? (
        <ScButton.LoadingIcon>
          <images.loader.component />
        </ScButton.LoadingIcon>
      ) : null}
      <span>{children}</span>
    </ScButton>
  );
};

export default Button;
