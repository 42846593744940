import React, { FC } from 'react';

import { ScBurger } from './styled';

interface BurgerProps {
  onClick: () => void;
  isOpen?: boolean;
}

const Burger: FC<BurgerProps> = ({ onClick, isOpen }) => {
  return (
    <ScBurger onClick={onClick} className={`${isOpen ? 'open' : ''}`}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </ScBurger>
  );
};

export default Burger;
