import React, { FC, Fragment, ReactNode, useCallback, useEffect } from 'react';

import cx from 'classnames';

import useAnimationTransition from 'hooks/animationTransition';

import { bodyScrollLockToggle } from 'helpers/common';

import { Portal } from 'components/portal';

import * as ScSidebar from './styled';

interface SideBarProps {
  children: ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
}

const SideBar: FC<SideBarProps> = ({ isOpen, onClose, children }) => {
  const { animationTransitionState, initAnimationTransition } =
    useAnimationTransition(2000);

  const handleClose = useCallback(() => {
    initAnimationTransition();
    onClose && onClose();
  }, [initAnimationTransition, onClose]);

  useEffect(() => {
    bodyScrollLockToggle(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (isOpen !== undefined) {
      initAnimationTransition();
    }
  }, [isOpen, initAnimationTransition]);

  return (
    <Portal>
      {(isOpen || animationTransitionState) && (
        <Fragment>
          <ScSidebar.Content className={cx('', isOpen && 'active')}>
            {children}
          </ScSidebar.Content>
          <ScSidebar.Background
            onClick={handleClose}
            className={cx('', isOpen && 'active')}
          />
        </Fragment>
      )}
    </Portal>
  );
};

export default SideBar;
