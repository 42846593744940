import { useEffect, useRef, useState } from 'react';

export function useOutsideClickVisibility(initialIsVisible?: boolean) {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef<HTMLInputElement>(null);

  const handleClickOutside = (e: MouseEvent | TouchEvent) => {
    if (
      ref.current != null &&
      e.target instanceof Node &&
      ref.current instanceof HTMLElement &&
      !ref.current.contains(e.target) &&
      isComponentVisible
    ) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}
