import { formatTypographyToFont } from 'helpers/typography';

import {
  gelatineAnimation,
  show,
  widgetAppearanceAnimation,
  widgetDisappearanceAnimation,
} from 'components/globalStyles/animation';

import styled from 'styled-components/macro';

import { SiteAssistantState } from './types';

export const AssistantBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: fixed;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.background.foreground};
  box-shadow: rgba(34, 36, 43, 0.3) 0 19px 38px 0;
  border: 1px solid ${({ theme }) => theme.colors.border.system};
  width: 55px;
  height: 55px;
  bottom: 130px;
  right: 26px;
  z-index: 3;
  transition: 0.3s all ease-in-out;
  animation: 0.3s linear ${widgetDisappearanceAnimation} forwards;
  @media (max-width: ${({ theme }) => theme.tabletSize}) {
    bottom: 94px;
  }
  &.${SiteAssistantState.ACTIVE} {
    animation: 0.3s linear ${widgetAppearanceAnimation};
    animation-delay: 0s, 1.5s;
  }
  &.${SiteAssistantState.NOTIFY} {
    animation: ${gelatineAnimation} 1.5s infinite;
  }
  svg {
    width: 40px;
    min-width: 40px;
    height: 40px;
    path {
      fill: ${({ theme }) => theme.colors.button.icon.icon01};
    }
  }
`;

export const AssistantPanel = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing['s-08']};
  border-radius: ${({ theme }) => theme.spacing['s-16']};
  background: ${({ theme }) => theme.colors.background.foreground};
  box-shadow: rgba(0, 0, 0, 0.25) 0 25px 50px -12px;
  border: 1px solid ${({ theme }) => theme.colors.border.system};
  position: fixed;
  top: 72px;
  right: 8px;
  height: 56px;
  width: 80dvw;
  max-width: 360px;
  transition:
    all 0.3s ease-in-out,
    opacity 0.4s cubic-bezier(0.75, 0, 0.25, 1);
  opacity: 0;
  z-index: 4;
  &.${SiteAssistantState.ACTIVE} {
    height: 72dvh;
    opacity: 1;
  }
`;

export const AssistantPanelHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0 3rem 0 1rem;
  h2 {
    font: ${({ theme }) =>
      formatTypographyToFont(theme.typography['heading-medium'])};
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

export const ClosePanelButton = styled.button`
  cursor: pointer;
  display: flex;
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  padding: 0;
  svg path {
    fill: ${({ theme }) => theme.colors.icons.primary};
  }
`;

export const AssistantPanelContent = styled.div`
  padding: 0 1rem 1rem;
  height: 100%;
  overflow-y: auto;
  & > * {
    opacity: 0;
    animation: ${show} 0.4s ease-in-out forwards;
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;
