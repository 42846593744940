import { Link } from 'react-navi';

import { dropDownShow } from 'components/globalStyles/animation';

import styled from 'styled-components/macro';

import { formatTypographyToFont } from '../../helpers/typography';

export const ScNavMenu = styled.div`
  @media (max-width: ${({ theme }) => theme.tabletSize}) {
    display: none;
  }
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
`;

export const ScNavbarLink = styled(Link)`
  padding: 0 16px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font: ${({ theme }) =>
    formatTypographyToFont(theme.typography['label-medium'])};
  color: ${({ theme }) => theme.colors.text.subtle};
  svg,
  path {
    fill: ${({ theme }) => theme.colors.icons.subtle};
    fill-opacity: 1;
  }
  [class$='stroke-color'] {
    stroke: ${({ theme }) => theme.colors.icons.subtle};
  }
  @media (min-width: ${({ theme }) => theme.mobileSize}) {
    &:hover {
      span {
        color: ${({ theme }) => theme.colors.text.primary};
      }
      svg,
      path {
        fill: ${({ theme }) => theme.colors.icons.primary};
        fill-opacity: 1;
      }
      [class$='stroke-color'] {
        stroke: ${({ theme }) => theme.colors.icons.primary};
      }
    }
  }
  &.active {
    @media (min-width: ${({ theme }) => theme.mobileSize}) {
      border-bottom: 1px solid ${({ theme }) => theme.colors.border.focus};
      background: ${({ theme }) => theme.colors.layer.layerSelected01};
      span {
        color: ${({ theme }) => theme.colors.text.primary};
      }
      svg {
        &,
        path {
          fill: ${({ theme }) => theme.colors.icons.primary};
          fill-opacity: 1;
        }
        [class$='stroke-color'] {
          stroke: ${({ theme }) => theme.colors.button.icon.icon01};
        }
      }
    }
  }
`;

export const ScMoreMenuDropdown = styled.div`
  padding: 0 24px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .dropdown-btn {
    width: 24px;
    height: 24px;
    cursor: pointer;
    &:hover {
      &,
      path,
      rect {
        fill: ${({ theme }) => theme.colors.background.brand};
        fill-opacity: 1;
      }
    }
  }
  &.active {
    .dropdown-btn {
      &,
      path,
      rect {
        fill: ${({ theme }) => theme.colors.background.brand};
        fill-opacity: 1;
      }
    }
  }
  .dropdown-menu {
    overflow: hidden;
    position: absolute;
    top: calc(100% + 16px);
    z-index: 10;
    min-width: 256px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    padding: 8px 12px 24px;
    background: ${({ theme }) => theme.colors.layer.layer01};
    border: 1px solid ${({ theme }) => theme.colors.border.system};
    border-radius: ${({ theme }) => theme.radius.mainStrong};
    box-shadow: 0 0 8px 4px #1c1e2920;
    transition: all linear 0.3s;
    animation: ${dropDownShow} ease 0.3s;
    hr {
      margin: 8px 24px;
      width: calc(100% - 48px);
      height: 1px;
      background-color: ${({ theme }) => theme.colors.border.separator};
      outline: none;
      border: none;
    }
    &__link {
      padding: 8px 12px;
      border-radius: 12px;
      display: flex;
      width: 100%;
      flex-grow: 1;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      font: 400 13px / 20px ${({ theme }) => theme.mainFont};
      color: ${({ theme }) => theme.colors.text.primary};
      &.addons {
        font-size: 12px;
        line-height: 14px;
        padding: 11px 12px;
      }
      &:hover {
        @media (min-width: ${({ theme }) => theme.mobileSize}) {
          background-color: ${({ theme }) => theme.colors.layer.layerHover01};
        }
      }
      svg {
        &,
        path,
        rect {
          height: 24px;
          width: 24px;
          fill: ${({ theme }) => theme.colors.icons.primary};
        }
      }
      div {
        display: flex;
        flex-direction: column;
        p {
          font: ${({ theme }) =>
            formatTypographyToFont(theme.typography['label-medium'])};
        }
        span {
          font: ${({ theme }) =>
            formatTypographyToFont(theme.typography['paragraph-small'])};
          color: ${({ theme }) => theme.colors.text.subtle};
        }
      }
    }
    &__mail {
      display: inline-flex;
      align-items: center;
      gap: 4px;
      padding: 0 12px;
      font: ${({ theme }) =>
        formatTypographyToFont(theme.typography['overline-medium'])};
      color: ${({ theme }) => theme.colors.text.info};
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
