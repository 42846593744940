import * as React from 'react';
import { SVGProps } from 'react';

const SvgEyeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="#F8FAFC"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10 2.5c4.493 0 8.232 3.233 9.016 7.5A9.17 9.17 0 0 1 .984 10C1.767 5.733 5.507 2.5 10 2.5Zm0 13.333A7.504 7.504 0 0 0 17.314 10a7.504 7.504 0 0 0-14.628 0A7.504 7.504 0 0 0 10 15.833Zm0-2.083a3.75 3.75 0 1 1 0-7.5 3.75 3.75 0 0 1 0 7.5Zm0-1.667a2.083 2.083 0 1 0 0-4.166 2.083 2.083 0 0 0 0 4.166Z" />
    </svg>
  );
};
export default SvgEyeIcon;
