import React, { Suspense, useContext, useEffect, useState } from 'react';

import * as Sentry from '@sentry/react';

import { compose, lazy, map, mount, redirect } from 'navi';
import { Router, View } from 'react-navi';

import { useApi } from 'hooks/api';
import { useTheme } from 'hooks/theme';

import { getCurrentUser } from 'api/user';

import { getLoggedEmail } from 'helpers/auth';

import { Layout } from 'components/Layout';

import { AuthServiceContext } from 'modules/authService/context';
import { AuthService } from 'modules/authService/service';

import { SeverityLevelType } from 'lib/sentry/types';
import { Theme } from 'themes/contracts';

function redirectFromPathLanguage(lng: string) {
  return redirect(
    (request) => {
      let path = request.path || '/';

      if (request.search) {
        if (!Object.prototype.hasOwnProperty.call(request.query, 'lng')) {
          path += request.search + `&lng=${lng}`;
        }
      } else {
        path += `?lng=${lng}`;
      }

      return path + request.hash;
    },
    { exact: false }
  );
}

const routes = compose(
  map(
    (
      _,
      context: {
        isAuthenticated: boolean;
        authService: AuthService;
        theme: Theme;
      }
    ) => {
      return mount({
        '/ru': redirectFromPathLanguage('ru'),
        '/en': redirectFromPathLanguage('en'),

        '/':
          context.theme.requiredAuthForExchange && !context.isAuthenticated
            ? redirect('/login')
            : lazy(() => import('modules/exchange/route')),
        '/profile/bids/confirmation': lazy(
          () => import('modules/exchangeConfirmation/route')
        ),
        '/reserve': !context.theme.isHideReserves
          ? lazy(() => import('modules/pages/reserve/route'))
          : redirect('/'),
        '/bonus': !context.theme.isHideBonuses
          ? lazy(() => import('modules/pages/bonus/route'))
          : redirect('/'),
        '/faq': lazy(() => import('modules/pages/faq/route')),
        '/profile': lazy(() => import('modules/pages/profile/route')),
        '/partner': !context.theme.isHidePartners
          ? lazy(() => import('modules/pages/partner/route'))
          : redirect('/'),
        '/terms-of-use': lazy(() => import('modules/pages/termsOfUse/route')),
        '/privacy': lazy(() => import('modules/pages/privacy/route')),
        '/policy': lazy(() => import('modules/pages/policy/route')),
        '/feedback': lazy(() => import('modules/pages/feedback/route')),
        '/login': lazy(() => import('modules/pages/login/route')),
        '/registration': lazy(() => import('modules/pages/registration/route')),
        '/perform_recovery': redirect(
          (req) => `/recovery/perform${req.search}`
        ),
        '/recovery': lazy(() => import('modules/pages/recovery/route')),
        '/user': lazy(() => import('modules/pages/user/route')),
        '/crypto/pay/:currency': lazy(
          () => import('modules/cryptoPayment/route')
        ),
        '/refund/:token': lazy(() => import('modules/pages/refund/route')),
        '/recalculate/:token': lazy(
          () => import('modules/pages/recalculation/route')
        ),
        '/resend/:token': lazy(() => import('modules/pages/resend/route')),
      });
    }
  )
);

function setAuthenticationBreadcrumb(isAuthenticated: boolean) {
  Sentry.addBreadcrumb({
    category: 'auth',
    message: isAuthenticated ? 'User logged in' : 'User logged out',
    level: SeverityLevelType.Info,
  });
}

export const Routes = () => {
  const authService = useContext(AuthServiceContext);

  const theme = useTheme();

  const [isAuthenticated, setIsAuthenticated] = useState(
    authService.isAuthenticated
  );

  const [getUserInfoRequest] = useApi(getCurrentUser);

  useEffect(() => {
    Sentry.setUser({ id: isAuthenticated ? getLoggedEmail() : 'anonymous' });
  }, [isAuthenticated]);

  useEffect(() => {
    authService.subscribe(setIsAuthenticated);
  }, [authService]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (isAuthenticated) {
        const { response } = await getUserInfoRequest().request;
        authService.setUserInfo(response?.data);
      }
    };

    fetchUserInfo();
  }, [authService, getUserInfoRequest, isAuthenticated]);

  useEffect(
    () => authService.subscribe(setAuthenticationBreadcrumb),
    [authService]
  );

  return (
    <Router routes={routes} context={{ isAuthenticated, authService, theme }}>
      <Layout>
        <Suspense fallback={null}>
          <View />
        </Suspense>
      </Layout>
    </Router>
  );
};
