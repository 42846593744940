import React, { Fragment, forwardRef, useCallback, useContext } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { useApi } from 'hooks/api';
import { useOutsideClickVisibility } from 'hooks/visibility';

import { editUser } from 'api/user';

import { showTooltip } from 'helpers/tooltip';
import { translationFactory } from 'helpers/trans';

import { Icon } from 'components/UI/Icon';

import { AuthServiceContext } from 'modules/authService/context';
import { TooltipTypes } from 'modules/globalTooltip';

import { Languages, availableLng } from 'lib/i18n';

import { LangToggleType } from './enum';
import { useLanguageChange } from './hooks';
import * as ScLangToggle from './styled';

const listOfLanguage = {
  [Languages.ENGLISH]: {
    name: 'English',
  },
  [Languages.RUSSIAN]: {
    name: 'Русский',
  },
};

interface LangToggleProps {
  type?: LangToggleType;
}

const LangToggle = forwardRef<HTMLDivElement, LangToggleProps>(
  ({ type = LangToggleType.Desktop }) => {
    const [changeLang] = useLanguageChange();
    const {
      i18n: { language },
    } = useTranslation();
    const { isAuthenticated } = useContext(AuthServiceContext);

    const { ref, isComponentVisible, setIsComponentVisible } =
      useOutsideClickVisibility(false);

    const [userEditRequest] = useApi(editUser);

    const toggle = useCallback(
      () => void setIsComponentVisible((i) => !i),
      [setIsComponentVisible]
    );

    const handleLanguageChange = useCallback(
      async (lng: string) => {
        await changeLang(lng);

        if (isAuthenticated) {
          const { error } = await userEditRequest({ langCode: lng }).request;

          if (error != null) {
            showTooltip({
              type: TooltipTypes.ERROR,
              body: translationFactory('common:error.unknown'),
            });
          }
        }
      },
      [changeLang, isAuthenticated, userEditRequest]
    );

    if (availableLng.length <= 1) return null;

    return (
      <ScLangToggle.Container
        ref={ref}
        onClick={toggle}
        className={cx('', type === LangToggleType.Desktop && 'hideOnMobile')}
      >
        <ScLangToggle.SelectedLang
          className={cx('', isComponentVisible && 'open')}
        >
          <span>{language.toUpperCase()}</span>
          <Icon name="Arrow" />
        </ScLangToggle.SelectedLang>

        {type === LangToggleType.Desktop && (
          <ScLangToggle.ListOfLang
            className={cx('', {
              open: isComponentVisible,
              close: !isComponentVisible,
            })}
          >
            {availableLng.map((lng, index) => (
              <li
                key={index}
                onClick={() => handleLanguageChange(lng)}
                className={cx('', {
                  selected: lng === language,
                })}
              >
                <p>{listOfLanguage[lng].name}</p>
                {lng === language && (
                  <Icon name="CheckmarkSelectedIcon" size="small" />
                )}
              </li>
            ))}
          </ScLangToggle.ListOfLang>
        )}

        {type === LangToggleType.Mobile && (
          <Fragment>
            <ScLangToggle.MobileListOfLang
              className={cx('', {
                open: isComponentVisible,
                close: !isComponentVisible,
              })}
            >
              <ScLangToggle.HolderBtn>
                <button />
              </ScLangToggle.HolderBtn>
              {availableLng.map((lng, index) => (
                <li
                  key={index}
                  onClick={() => handleLanguageChange(lng)}
                  className={cx('', {
                    selected: lng === language,
                  })}
                >
                  <p>{listOfLanguage[lng].name}</p>
                  {lng === language && (
                    <Icon name="CheckmarkSelectedIcon" size="medium" />
                  )}
                </li>
              ))}
            </ScLangToggle.MobileListOfLang>

            <ScLangToggle.MobileOverlay
              className={cx('', {
                open: isComponentVisible,
                close: !isComponentVisible,
              })}
            />
          </Fragment>
        )}
      </ScLangToggle.Container>
    );
  }
);

export default LangToggle;
