import { ENV } from 'env/env.config';

const widgetScript = (key: string) => `
(function () {
        const widget_id = '${key}';
        if (!widget_id) return;
        const d = document;
        const w = window;
        function l() {
          const s = document.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = '//code.jivosite.com/script/widget/' + widget_id;
          const ss = document.getElementsByTagName('script')[0];
          ss.parentNode.insertBefore(s, ss);
        }
        if (d.readyState == 'complete') {
          l();
        } else {
          if (w.attachEvent) {
            w.attachEvent('onload', l);
          } else {
            w.addEventListener('load', l, false);
          }
        }
      })();

      function jivo_onLoadCallback() {
        window.jivo_cstm_widget = document.createElement('div');
        jivo_cstm_widget.setAttribute('id', 'jivo_custom_widget');
        document.body.appendChild(jivo_cstm_widget);

        jivo_cstm_widget.onclick = () => {
          jivo_api.open();
        };

        if (jivo_config.chat_mode === 'online') {
          jivo_cstm_widget.setAttribute('class', 'jivo_online');
        }

        window.jivo_cstm_widget.style.display = 'block';
      }
      function jivo_onOpen() {
        if (jivo_cstm_widget) jivo_cstm_widget.style.display = 'none';
      }
      function jivo_onClose() {
        if (jivo_cstm_widget) jivo_cstm_widget.style.display = 'block';
      }
`;

export const jivositeWidget = {
  key: ENV.JIVOSITE_ID,
  add: function () {
    if (this.key == null) return;

    const script = document.createElement('script');
    script.id = `script_${this.key}`;
    script.async = true;
    script.innerHTML = widgetScript(this.key);
    document.head.appendChild(script);
  },
  remove: function () {
    const elements = [document.getElementById(`script_${this.key}`)];

    elements.forEach((element) => {
      if (element != null) {
        element.remove();
      }
    });
  },
};
